<template>
    <div>
        <form-element-group>
            <template v-slot:col-1>
                <form-text-field
                    ref="name"
                    :value="model.name"
                    name="name"
                    icon="person"
                    :placeholder="$t('applications.enter-contact-name')"
                    :label="$t('applications.contact-name')"
                    @blur="update('name', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    ref="phone"
                    name="phone"
                    :value="model.phone"
                    :label="$t('applications.phone')"
                    icon="phone_iphone"
                    :rules="[
                        (v) =>
                            v.length == 0 ||
                            v.length == 14 ||
                            $t('applications.phone-number-invalid'),
                    ]"
                    mask="(###)-###-####"
                    return-masked-value
                    :placeholder="$t('applications.enter-phone')"
                    @blur="update('phone', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
        <form-element-group>
            <template v-slot:col-1>
                <form-text-field
                    ref="relationship"
                    :value="model.relationship"
                    name="relationship"
                    :placeholder="$t('applications.enter-relationship')"
                    :label="$t('applications.relationship')"
                    @blure="update('relationship', $event)"
                ></form-text-field>
            </template>
            <template v-slot:col-2>
                <form-text-field
                    ref="address"
                    name="address"
                    v-model="model.address"
                    :label="$t('applications.address')"
                    icon="location_city"
                    :placeholder="$t('applications.enter-address')"
                    @blur="update('address', $event)"
                ></form-text-field>
            </template>
        </form-element-group>
    </div>
</template>

<script>
export default {
    name: "applicant-emergency-contact",
    components: {},
    props: {
        modelData: {
            type: Object,
            required: true,
            validator: (value) => {
                const params = Object.keys(value);
                return [
                    "id",
                    "address",
                    "name",
                    "phone",
                    "relationship",
                ].every((elem) => params.includes(elem));
            },
        },
    },
    computed: {},
    data() {
        return {
            model: this.modelData,
        };
    },
    methods: {
        update(key, data) {
            this.model[key] = data;
            this.$emit("update", this.model);
        },
    },
};
</script>

<style scoped>
</style>