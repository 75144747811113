var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "name",
                  attrs: {
                    value: _vm.model.name,
                    name: "name",
                    icon: "person",
                    placeholder: _vm.$t("applications.enter-contact-name"),
                    label: _vm.$t("applications.contact-name")
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("name", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "phone",
                  attrs: {
                    name: "phone",
                    value: _vm.model.phone,
                    label: _vm.$t("applications.phone"),
                    icon: "phone_iphone",
                    rules: [
                      function(v) {
                        return (
                          v.length == 0 ||
                          v.length == 14 ||
                          _vm.$t("applications.phone-number-invalid")
                        )
                      }
                    ],
                    mask: "(###)-###-####",
                    "return-masked-value": "",
                    placeholder: _vm.$t("applications.enter-phone")
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("phone", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("form-element-group", {
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "relationship",
                  attrs: {
                    value: _vm.model.relationship,
                    name: "relationship",
                    placeholder: _vm.$t("applications.enter-relationship"),
                    label: _vm.$t("applications.relationship")
                  },
                  on: {
                    blure: function($event) {
                      return _vm.update("relationship", $event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return [
                _c("form-text-field", {
                  ref: "address",
                  attrs: {
                    name: "address",
                    label: _vm.$t("applications.address"),
                    icon: "location_city",
                    placeholder: _vm.$t("applications.enter-address")
                  },
                  on: {
                    blur: function($event) {
                      return _vm.update("address", $event)
                    }
                  },
                  model: {
                    value: _vm.model.address,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "address", $$v)
                    },
                    expression: "model.address"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }